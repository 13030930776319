import "lazysizes";
import { normalizeVH } from "./bundle/rcobiella-helpers.js";
import gsap, { Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import ScrollBooster from "scrollbooster";
import Cookies from "js-cookie";

gsap.registerPlugin(ScrollTrigger, ScrambleTextPlugin);

normalizeVH();

window.onresize = () => {
	normalizeVH();
};

/* -------------------------------------------------------------------------- */
/*                              LazySizes config                              */
/* -------------------------------------------------------------------------- */

window.lazySizesConfig = {
	loadMode: 3,
	expand: 1000,
};

const isMobile = "ontouchstart" in window;

jQuery(function ($) {
	/* ---------------------------------------------------------------- */
	/*                             Pointer                              */
	/* ---------------------------------------------------------------- */
	function init_pointer2() {
		// Gets the mouse position
		const getMousePos = (e) => {
			return {
				x: e.clientX,
				y: e.clientY,
			};
		};
		// Linear interpolation
		const lerp = (a, b, n) => (1 - n) * a + n * b;

		//Grab mouse position and set it to mouse state
		let mouse = { x: 0, y: 0 };
		window.addEventListener("mousemove", (ev) => (mouse = getMousePos(ev)));

		class Cursor {
			constructor(el) {
				this.Cursor = el;
				this.Follower = document.querySelector(".cursor-follow");
				this.Cursor.style.opacity = 0;

				this.cursorCnfigs = {
					x: { previous: 0, current: 0, amt: 0.2 },
					y: { previous: 0, current: 0, amt: 0.2 },
				};

				this.onMouseMoveEv = () => {
					this.cursorCnfigs.x.previous = this.cursorCnfigs.x.current =
						mouse.x;
					this.cursorCnfigs.y.previous = this.cursorCnfigs.y.current =
						mouse.y;

					// Set cursor opacity to 1 when hovered on the screen
					gsap.to(this.Cursor, {
						duration: 1,
						ease: "Power3.easeInOut",
						opacity: 1,
					});
					gsap.to(this.Follower, {
						duration: 1.2,
						ease: "Power3.easeInOut",
						opacity: 1,
					});

					//    requestAnimationFrame
					requestAnimationFrame(() => this.render());

					//    Cleanup function
					window.removeEventListener("mousemove", this.onMouseMoveEv);
				};
				//    Assign the mouse function
				window.addEventListener("mousemove", this.onMouseMoveEv);
				const cursorDis = this;

				$("[data-cursor]").on("mouseenter mouseleave", function (e) {
					const $dis = $(this);
					if (e.type == "mouseenter") {
						cursorDis.Cursor.classList.add("is-hover");
						cursorDis.Follower.classList.add("is-hover");
					} else {
						cursorDis.Cursor.classList.remove("is-hover");
						cursorDis.Follower.classList.remove("is-hover");
					}
				});
			}

			render() {
				this.cursorCnfigs.x.current = mouse.x;
				this.cursorCnfigs.y.current = mouse.y;
				for (const key in this.cursorCnfigs) {
					this.cursorCnfigs[key].previous = lerp(
						this.cursorCnfigs[key].previous,
						this.cursorCnfigs[key].current,
						this.cursorCnfigs[key].amt
					);
				}
				//    setting the cursor x and y to our cursor html element
				this.Cursor.style.transform = `translate3d(${this.cursorCnfigs.x.previous}px,${this.cursorCnfigs.y.previous}px, 0)`;
				this.Follower.style.transform = `translate3d(${this.cursorCnfigs.x.previous}px,${this.cursorCnfigs.y.previous}px, 0)`;

				requestAnimationFrame(() => this.render());
			}
		}
		const cursor = new Cursor(document.querySelector(".cursor"));

		window.addEventListener("load", function () {
			let c2 = document.querySelector(".cursor-follow"),
				c1 = document.querySelector(".cursor");

			gsap.to(c2, {
				autoAlpha: 1,
				duration: 1.5,
				delay: 1.5,
			});
			gsap.to(c1, {
				autoAlpha: 1,
				duration: 1.5,
				delay: 1.5,
			});
		});
	}
	if (!isMobile) {
		init_pointer2();
	}

	/* ---------------------------------------------------------------- */
	/*                          Hero scramble                           */
	/* ---------------------------------------------------------------- */

	function initScramble() {
		$("[data-scramble]").each(function () {
			let dis = this,
				finalText = dis.innerHTML;

			if (!isMobile) {
				gsap.to(dis, {
					duration: 2.5,
					scrambleText: {
						text: "{original}",
						chars: "lowerCase",
						revealDelay: 0.25,
						tweenLength: false,
					},
				});
			}

			if (dis.classList.contains("hero-title")) {
				dis.classList.add("reveal");

				setTimeout(function () {
					let tx = document.querySelector(".hero-text");
					if (tx) {
						tx.classList.add("reveal");
					}
				}, 2000);
			}
		});
	}

	function initScrollbooster() {
		console.log("scrollbooster", isMobile);
		if (!isMobile) {
			$(".h-scroller").each(function () {
				const $dis = $(this),
					$scroller = $dis.find(".h-wrapper");

				if ($scroller.length) {
					// Prepare vars
					let width, visibleWidth, percVisible;

					var scrollHoriz = new ScrollBooster({
						viewport: $dis[0],
						content: $scroller[0],
						scrollMode: "native", // use CSS 'transform' property
						direction: "horizontal", // allow only horizontal scrolling
						emulateScroll: true, // scroll on wheel events
						bounceForce: 0.025,
						// textSelection: false,
						dragDirectionTolerance: 0,
					});

					window.sb = scrollHoriz;
				}
			});
		}
	}
	var allLoaded = false;

	window.onload = function () {
		initScramble();
		initScrollbooster();
		allLoaded = true;
	};
	setTimeout(function () {
		if (!allLoaded) {
			initScramble();
			initScrollbooster();
		}
	}, 1500);

	/* ---------------------------------------------------------------- */
	/*                             Cookies                              */
	/* ---------------------------------------------------------------- */

	function initCookieNotice() {
		const savedCookie = Cookies.get("baup_cookieAccept");
		if (savedCookie) return false;

		const $cookie = $("#cookies"),
			$cookieClose = $cookie.find("#cookies-close"),
			$cLink = $cookie.find("a");

		if ($cookie.length && $cookieClose.length) {
			if ($cLink.length) {
				$cLink[0].target = "_blank";
				$cLink[0].rel = "nofollow";
			}

			gsap.to($cookie, {
				duration: 2,
				ease: Power3.easeInOut,
				y: 0,
				z: 0.01,
				visibility: "visible",
				delay: 4,
			});

			$cookieClose.on("click", acceptCookie);

			function acceptCookie() {
				// Set cookie for 30 days
				Cookies.set("baup_cookieAccept", "1", { expires: 30 });
				gsap.to($cookie, {
					duration: 1,
					autoAlpha: 0,
				});
			}
		}
	}

	initCookieNotice();
	/* ---------------------------------------------------------------- */
	/*                              Times                               */
	/* ---------------------------------------------------------------- */

	var initialHourSpain, initialHourPortugal;

	function timeConverter(UNIX_timestamp) {
		var a = new Date(UNIX_timestamp);
		var hour = a.getHours();
		var min = a.getMinutes();
		var sec = a.getSeconds();
		var time =
			"<span class='hours'>" +
			(hour < 10 ? `0${hour}` : hour) +
			"</span>" +
			"<span class='dots'>:</span>" +
			"<span class='minutes'>" +
			(min < 10 ? `0${min}` : min) +
			"</span>" +
			"<span class='dots'>:</span>" +
			"<span class='seconds'>" +
			(sec < 10 ? `0${sec}` : sec) +
			"</span>";
		return time;
	}

	Date.prototype.addHours = function (h) {
		this.setTime(this.getTime() + h * 60 * 60 * 1000);
		return this;
	};

	function getTime(url) {
		return new Promise((resolve, reject) => {
			const req = new XMLHttpRequest();
			req.open("GET", url);
			req.onload = () =>
				req.status === 200
					? resolve(req.response)
					: reject(Error(req.statusText));
			req.onerror = (e) => reject(Error(`Network Error: ${e}`));
			req.send();
		});
	}
	let url = "https://worldtimeapi.org/api/timezone/Europe/Madrid";

	getTime(url)
		.then((response) => {
			let dateObj = JSON.parse(response);
			let dateTime = dateObj.datetime;
			if (dateTime) {
				let dateParsed = Date.parse(dateTime);
				initialHourSpain = dateParsed;
				initialHourPortugal = dateParsed - 3600000;

				$(".time.es")[0].innerHTML = timeConverter(initialHourSpain);
				$(".time.por")[0].innerHTML = timeConverter(
					initialHourPortugal
				);

				$(".time-wrap").addClass("is-updated");

				setInterval(function () {
					initialHourSpain = +initialHourSpain + 1000;
					initialHourPortugal = +initialHourPortugal + 1000;

					$(".time.es")[0].innerHTML = timeConverter(
						initialHourSpain
					);
					$(".time.por")[0].innerHTML = timeConverter(
						initialHourPortugal
					);
				}, 1000);
			}
		})
		.catch((err) => {
			console.log(err);
		});

	/* ---------------------------------------------------------------- */
	/*                            Servicios                             */
	/* ---------------------------------------------------------------- */

	$(".servicios-section").on("click", function () {
		const $dis = $(this),
			$shown = $dis.find(".servicios-visible"),
			$hidden = $dis.find(".servicios-hidden");
		$shown.slideToggle();
		$dis.toggleClass("is-open");
		$hidden.slideToggle();
	});

	/* ---------------------------------------------------------------- */
	/*                             Marquees                             */
	/* ---------------------------------------------------------------- */

	$(".marquee:not(.marquee-auto)").each(function () {
		const $dis = $(this),
			$wrapper = $dis.find(".marquee-wrap");

		gsap.set($wrapper, {
			z: 0.01,
			x: 0,
			// y: "-50%",
		});
		gsap.to($wrapper, {
			scrollTrigger: {
				trigger: $wrapper[0], //this is the element that will trigger the animation
				start: "top bottom", //this string defines the trigger and scroller start
				end: "bottom top", //this string defines the trigger and scroller end
				scrub: true, //this tells GSAP to link animation progress to scroll progress
			},
			x: $dis[0].offsetWidth - $wrapper[0].scrollWidth,
			z: 0.01,
			// ease: Power1.easeOut,
		});
	});

	/* ---------------------------------------------------------------- */
	/*                               Menu                               */
	/* ---------------------------------------------------------------- */

	const $menuTrigger = $(".menu-trigger"),
		$menuOverlay = $(".menu-overlay"),
		$menuItems = $menuOverlay.find("li.menu-item, div.languages");

	$menuTrigger.on("click", toggleMenu);

	function toggleMenu() {
		$("body").toggleClass("menu-is-open");
		if ($("body").hasClass("menu-is-open")) {
			openMenu();
		} else {
			closeMenu();
		}
	}

	function openMenu() {
		$menuTrigger.addClass("is-open");

		let delay = 0;

		gsap.to($menuOverlay, {
			duration: 0.5,
			autoAlpha: 1,
		});

		gsap.to($menuItems, {
			duration: 0.5,
			autoAlpha: 1,
			y: 0,
			stagger: 0.2,
			ease: "Power1.easeInOut",
		});
	}

	function closeMenu() {
		gsap.to($menuItems, {
			duration: 0.5,
			autoAlpha: 0,
			y: "100%",
			stagger: 0.1,
			ease: "Power1.easeInOut",

			onComplete: function () {
				$menuTrigger.removeClass("is-open");
				gsap.to($menuOverlay, {
					duration: 0.5,
					autoAlpha: 0,
				});
				$("body").removeClass("menu-is-open");
			},
		});
	}

	// click on links
	$menuItems.find("> a").on("click", function () {
		const $dis = $(this),
			linkHref = $dis[0].href;

		if (linkHref.indexOf("#") > -1) {
			closeMenu();
		}
	});

	/* ---------------------------------------------------------------- */
	/*                    Nosotros - images scroller                    */
	/* ---------------------------------------------------------------- */

	$(".has-scroller").each(function () {
		const $dis = $(this),
			$wrapper = $dis.find(".images-scroller");

		gsap.set($wrapper, {
			z: 0.01,
			x: 0,
			// y: "-50%",
		});
		gsap.to($wrapper, {
			scrollTrigger: {
				trigger: $wrapper[0], //this is the element that will trigger the animation
				start: "top bottom", //this string defines the trigger and scroller start
				end: "bottom top", //this string defines the trigger and scroller end
				scrub: true, //this tells GSAP to link animation progress to scroll progress
			},
			x: $dis[0].offsetWidth - $wrapper[0].scrollWidth,
			z: 0.01,
			// ease: Power1.easeOut,
		});
	});

	/* -------------------------------------------------------------------------- */
	/*                                Loader + AOS                                */
	/* -------------------------------------------------------------------------- */

	let ignore_unload = false;

	// Prevent if link has mailto
	$("a[href^=mailto], a[href^=tel]").on("click", function () {
		ignore_unload = true;
	});

	$(window).on("beforeunload", function (e) {});

	window.addEventListener("beforeunload", onBeforeUnload);

	function onBeforeUnload() {
		if (!ignore_unload) {
			$("#loader").removeClass("is-loaded");
		}
		ignore_unload = false;
		delete e["returnValue"];
	}

	// console.log("before load");

	// window.addEventListener("DOMContentLoaded", windowLoad);

	// $(window).on("load", function () {
	// 	// Remove preload-transitions class from body to re-enable transitions
	// 	$("body").removeClass("preload-transitions");
	// 	$("#loader").addClass("is-loaded");
	// });
});

function baupOnLoad() {
	document.body.classList.remove("preload-transitions");
	document.getElementById("loader").classList.add("is-loaded");
}

window.addEventListener("load", baupOnLoad);

setTimeout(function () {
	baupOnLoad();
}, 1500);
